<template>
  <div class="grid grid-cols-3 w-full gap-4 overflow-scroll">
    <div
      v-for="slide in slides"
      :key="slide.name"
      style="border-radius: 24px; background-repeat: no-repeat"
      class="w-full bg-cover col-span-1 relative image-container md:grow overflow-hidden"
    >
      <img :src="slide.image" :alt="slide.name" class="w-full h-full object-cover" />
      <div class="z-50 flex flex-col gap-1 absolute bottom-10 left-5">
        <h4 class="text-white font-bold text">{{ slide.name }}</h4>
        <h6 class="text-white text-xs capitalize text">{{ slide.role }}</h6>
      </div>
      <div class="overlay"></div>
    </div>
  </div>
</template>

<script>
import Asher from "@/www/pages/about/assets/asher.png";
import Efosa from "@/www/pages/about/assets/efosa.png";
import Kingsley from "@/www/pages/about/assets/kingsley.png";
export default {
  data() {
    return {
      isDragging: false,
      startX: 0,
      currentTranslateX: 0,
      translateX: 0,
      slideWidth: 200, // Adjusted width of each slide
      spacing: 50, // Adjust spacing between slides
      currentIndex: 0,
      slides: [
        {
          image: Asher,
          name: "Asher Adeniyi",
          role: "Chief Executive Officer",
        },
        {
          image: Efosa,
          name: "Emmanuel Efosa-zuwa",
          role: "Chief Product & growth officer",
        },
        {
          image: Kingsley,
          name: "Kingsley Michael",
          role: "Chief Technology Officer",
        },
      ],
    };
  },
  computed: {
    slideVisibleWidth() {
      return this.slideWidth + this.spacing;
    },
    slidesWithDuplicates() {
      // Duplicate the slides to enable infinite scrolling
      return [...this.slides.slice(-1), ...this.slides, ...this.slides.slice(0, 1)];
    },
  },
  methods: {
    onTouchStart(event) {
      this.isDragging = true;
      this.startX = event.touches[0].clientX;
    },
    onTouchMove(event) {
      if (!this.isDragging) return;

      const currentX = event.touches[0].clientX;
      const deltaX = currentX - this.startX;
      this.translateX = this.currentTranslateX + deltaX;
    },
    onTouchEnd() {
      this.isDragging = false;

      // Calculate the index of the slide based on the translation
      const newIndex = Math.round(-this.translateX / this.slideVisibleWidth);
      this.goToSlide(newIndex);
    },
    goToSlide(index) {
      // Infinite scrolling logic
      if (index < 0) {
        // If transitioning from the first duplicate slide to the last actual slide
        this.currentIndex = this.slides.length - 1;
        this.translateX = -this.currentIndex * this.slideVisibleWidth;
      } else if (index >= this.slidesWithDuplicates.length - 2) {
        // If transitioning from the last actual slide to the first duplicate slide
        this.currentIndex = 0;
        this.translateX = -this.currentIndex * this.slideVisibleWidth;
      } else {
        this.currentIndex = index;
        this.translateX = -index * this.slideVisibleWidth;
      }

      this.currentTranslateX = this.translateX;
    },
  },
};
</script>

<style>
.carousel {
  overflow: hidden;
}

.carousel-track {
  display: flex;
  transition: transform 0.3s ease;
}

.carousel-slide {
  flex: 0 0 auto;
  width: 200px; /* Adjust based on your slide width */
  height: 200px; /* Adjust based on your slide height */
  margin-right: 50px; /* Adjust based on your desired spacing */
  width: 508.618px;
  height: 708.188px;
}
</style>
