<template>
  <Carousel :value="banners" circular :responsiveOptions="responsiveOptions" :showNavigators="!smallerThanLg">
    <template #item="slotProps">
      <div class="flex my-12 w-full gap-5">
        <div
          style="border-radius: 24px; height: 500px; background-repeat: no-repeat; width: 508.618px; height: 708.188px"
          class="mx-auto bg-cover col-span-1 relative image-container md:grow overflow-hidden"
        >
          <img :src="slotProps.data.image" :alt="slotProps.data.name" class="w-full h-full object-cover" />
          <div class="z-50 flex flex-col gap-1 absolute bottom-10 left-5">
            <h4 class="text-white font-bold text">{{ slotProps.data.name }}</h4>
            <h6 class="text-white text-xs capitalize text">{{ slotProps.data.role }}</h6>
          </div>
          <div class="overlay"></div>
        </div>
      </div>
    </template>
  </Carousel>
</template>

<script setup>
import { useDataStore } from "@/stores/data.js";

import Asher from "@/www/pages/about/assets/asher.png";
import Efosa from "@/www/pages/about/assets/efosa.png";
import Kingsley from "@/www/pages/about/assets/kingsley.png";

import { computed, ref } from "vue";
import { breakpointsTailwind, useBreakpoints } from "@vueuse/core";
import { useRouter } from "vue-router";

const store = useDataStore();

const router = useRouter();

const breakpoints = useBreakpoints(breakpointsTailwind);
const smallerThanLg = breakpoints.smaller("sm");
const companyDetails = computed(() => store.getCompanyDetails);

const banners = computed(() => {
  const arr = [
    {
      image: Asher,
      name: "Asher Adeniyi",
      role: "Chief Executive Officer",
    },
    {
      image: Efosa,
      name: "Emmanuel Efosa-zuwa",
      role: "Chief Product & growth officer",
    },
    {
      image: Kingsley,
      name: "Kingsley Michael",
      role: "Chief Technology Officer",
    },
  ];
  return arr;
});

const responsiveOptions = ref([
  {
    breakpoint: "1400px",
    numVisible: 2,
    numScroll: 1,
  },
  {
    breakpoint: "1199px",
    numVisible: 2,
    numScroll: 1,
  },
  {
    breakpoint: "767px",
    numVisible: 1,
    numScroll: 1,
  },
  {
    breakpoint: "575px",
    numVisible: 1.3,
    numScroll: 1,
  },
]);
</script>

<style scoped>
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.6) 100%);
  transition: opacity 0.3s ease;
}
</style>
